import Service from "../Service";
 
const resource = "TrsPaymentScheduling/";

export default {
    save(cmp, requestID) {
        return Service.post(resource + "save", cmp, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    list(requestID) {
        return Service.post(resource + "list", {}, {
            params: { requestID: requestID },
        });
    },

    listPays(requestID) {
        return Service.post(resource + "listPays", {}, {
            params: { requestID: requestID },
        });
    },

    GetItemsPays(PsgId, requestID) {
        return Service.post(resource + "GetItemsPays", {}, {
            params: { PsgId:PsgId, requestID: requestID },
        });
    },
};
<template lang="">
    <v-card>
        <div id="containerIA" v-show="!dialogIA">
            <div id="circleIA" style="position: relative; width: 100%; height: 100%;">
                <button id="manguitoIA" @click="dialogIA = true">
                    <img src="/static/ManguitoIA.png" width="40"/>
                </button>
                <div id="iconIA">
                </div>
            </div>
        </div>
        <div id="containerIADialog" v-show="dialogIA">
            <div id="contentIADialog">
                <div id="headerIADialog" style="padding: 5px; display: flex; align-items: center; flex-direction: row; justify-content: space-between; border-radius: 10px; box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.3); user-select: none; background-color: #fffffff2; backdrop-filter: blur(5px);">
                    <img src="/static/ManguitoIA.png" width="30"/>
                    <div style="display: flex; align-items: center; flex-direction: row; justify-content: space-between; width: 100%; padding: 0px 10px;">
                        <b style="font-size: 14px; letter-spacing: 0.5px;">SunExpert IA</b> 
                        <button style="display: flex; align-items: center; justify-content: center;" @click="dialogIA = false">
                            <i style="font-size: 14px;" class="fas fa-compress-alt"></i>
                        </button>
                    </div>   
                </div>
                <div id="bodyIADialog" style="position: relative; width: 100%; height: 100%; overflow: hidden; background-color: #ffffffc3; backdrop-filter: blur(5px); border-radius: 10px; display: flex; gap: 5px; flex-direction: column; padding: 5px">
                    <div style="flex: 1; border-radius: 10px; overflow-x: hidden; display: flex; flex-direction: column; justify-content: end;">
                        <div id="messagesIADialog" style="overflow-x: hidden; overflow-y: auto; padding: 0px 5px; display: flex; gap: 5px; flex-direction: column;">
                        </div>
                    </div>
                    <div style="background-color: #fffffff2; height: 35px; display: flex; align-items: center; border-radius: 10px; box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.3);">
                        <input :disabled="asking" @keydown.enter="handleEnter" v-model="textUser" type="text" style="width: 100%; height: 100%; border: none; outline: none; padding: 0px 0px 0px 10px; font-size: 14px; border-radius: 10px;"
                            :placeholder="asking ? 'Generando respuesta...' : '¿En que puedo ayudarte?'"
                        ><i class="fas fa-search" style="padding: 0px 10px"></i></input>
                    </div>
                </div>
            </div>
            <div id="iconIADialog">
            </div>
        </div>
        <s-crud
            :config="config"
            title="Programación de Pagos"
            :filter="filter"
            search-input
            add
            edit
            remove
            @addEvent="addNewPays()"
            @save="savePays($event)"
            @rowSelected="rowSelected($event)"
            @EditEvent="rowSelected($event)"
            @removed="removedPays()"
        >
            <template v-slot:filter>
                <v-container>
                    <v-row style="margin-bottom: 5px;">
                        <v-col>
                            <s-text
                                label="Cuenta"
                                v-model="filter.PsgAccount"
                            ></s-text>
                        </v-col>
                        <v-col>
                            <s-date
                                v-model="filter.PsgDateDesde"
                                label="Fecha Desde"
                            ></s-date>
                        </v-col>
                        <v-col>
                            <s-date
                                v-model="filter.PsgDateHasta"
                                label="Fecha Hasta"
                            ></s-date>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot="props">
                <v-container>
                    <v-row>
                        <v-col style="font-weight: bold; font-size: 20px;">POR PROGRAMAR PAGO</v-col>   
                    </v-row>
                    <v-row >                                        
                         <v-col cols="12">
                            <v-data-table
                                dense
                                fixed-header=""
                                :items="itemsSearch"
                                :headers="headersSearch"
                                :items-per-page="5"
                            >
                            <template v-slot:item.AsgAction="{ item }">
                                <v-btn
                                color="primary"
                                x-small
                                @click="AsgAddCard(item)"
                                block
                                >
                                <v-icon small>mdi-calendar-plus</v-icon>
                                </v-btn>
                            </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <hr style="margin: 20px 0px;">
                    <v-row>
                        <v-col style="font-weight: bold; font-size: 20px;">PROGRAMADOS</v-col>   
                    </v-row>
                    <v-row >
                        <v-col sm="3">
                            <s-date
                                v-model="props.item.PsgDate"
                                label="Fecha"
                            ></s-date>
                        </v-col>
                        <v-col sm="3">
                            <s-text
                                label="Cuenta"
                                v-model="props.item.PsgAccount"
                            ></s-text>
                        </v-col>
                    </v-row>
                    <v-row >                                        
                         <v-col cols="12">
                            <v-data-table
                                dense
                                fixed-header=""
                                :items="filtereditemsScheduled"
                                :headers="headersScheduled"
                                :items-per-page="5"
                            >
                            <template v-slot:item.AsgActionScheduled="{ item }">
                                <v-btn
                                color="error"
                                x-small
                                @click="AsgDeleteCard(item)"
                                block
                                >
                                <v-icon small>mdi-calendar-remove</v-icon>
                                </v-btn>
                            </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <hr style="margin: 20px 0px;">
                </v-container>
            </template>
        </s-crud>
    </v-card>
</template>

<script>

import SText from '../../../components/Utils/SText.vue';
import PaymentScheduling from '@/services/Treasury/PaymentScheduling';
import axios from "../../../services/Service";

export default {
    components: {
        SText
    },
    data() {
        return {
            asking: false,
            dialogIA: false,
            apiKey: "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",
            textUser: "",
            dataRemove: {},
            PsgId: 0,
            PsgIdRemove: 0,
            filterUsers: {
                searchText: ""
            },
            filter: {
                PsgAccount: "",
                PsgDateDesde: "",
                PsgDateHasta: ""
            },
            config: {
                service: PaymentScheduling,
                model: {
                    PsgId: "ID",
                    PsgDate: "date",
                },
                headers: [
                    { text: "ID", value: "PsgId" },
                    { text: "Fecha", value: "PsgDate" },
                    { text: "Monto", value: "PsgAmount" },
                    { text: "Cuenta", value: "PsgAccount" },
                    { text: "Semana", value: "PsgWeek" },
                ]
            },
            headersSearch: [
				// { text: "ID", value: "RcfID" },
				{ text: "Cod", value: "PrdCardCodeRc" },
				{ text: "Proveedor", value: "PrdCardNameRc" },
				{ text: "Guia", value: "RcfNumberReferralGuide" },
				{ text: "Monto", value: "AsgAmount" },
				{ text: "Semana", value: "PsgWeek" },
				{ text: "Nave", value: "TypeShipName" },
				{ text: "Acción", value: "AsgAction", width: 100, align: "center" },
			],
            headersScheduled: [
                // { text: "ID", value: "RcfID" },
				{ text: "Cod", value: "PrdCardCodeRc" },
				{ text: "Proveedor", value: "PrdCardNameRc" },
				{ text: "Guia", value: "RcfNumberReferralGuide" },
				{ text: "Monto", value: "AsgAmount" },
				{ text: "Semana", value: "PsgWeek" },
				{ text: "Nave", value: "TypeShipName" },
                { text: "Acción", value: "AsgActionScheduled", width: 100, align: "center" },
            ],
            itemsSearch: [
                // {
                //     RcfID: 9856,
                //     PrdCardCodeRc: "PP20604284865",
                //     PrdCardNameRc: "EXPORTACIONES & IMPORTACIONES AGRICOLA DEL VALLE SOCIEDAD ANONIMA CERRADA",
                //     RcfNumberReferralGuide: "5524-84356",
                //     AsgAmount: "5000.00",
                //     PsgWeek: "43",
                //     TypeShip: "1",
                // },
                // {
                //     RcfID: 9854,
                //     PrdCardCodeRc: "PP00003222111",
                //     PrdCardNameRc: "HUAMAN CRUZ ERMILDO",
                //     RcfNumberReferralGuide: "1231-23123",
                //     AsgAmount: "6000.00",
                //     PsgWeek: "43",
                //     TypeShip: "1",
                // }
            ], //Listado
            itemsScheduled: [], //Listado Programados
            AsgAction: "",
            AsgActionScheduled: "",
        }
    },
    methods: {
        markdownToHTML(inputText) {
        let sections = inputText.split(/###\s*/).filter(Boolean);

        let formattedSections = sections.map((section) => {
            section = section.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');

            section = section.replace(/-\s*(.*?)(?=\n|$)/g, '<li>$1</li>');

            section = section.replace(/([^\n]+(?:-\s[^\n]+)+)/g, (match) => {
                const listItems = match.split(' - ').map(item => `<li>${item.trim()}</li>`).join('');
                return `<ul>${listItems}</ul>`;
            });

            return section;
        });

        return formattedSections.join('<br>'); 
        },
        async handleEnter() {
            if(this.asking) return;
            if (this.textUser.trim() === "") return;
            // console.log("Pregunta enviada:", this.textUser);
            this.asking = true;

            let messageUser = `
            <div class="userMessage">
                <p>${this.textUser}</p>
            </div>
            `
            document.getElementById("messagesIADialog").innerHTML += messageUser;

            let messageIA = this.textUser;

            this.textUser = "";

            const context = "Eres un asistente virtual llamado SunExpert IA y trabajas para Sunshine Export SAC.";

            let data = {
                model: 'gpt-4o-mini',
                messages: [
                    { role: 'system', content: context },
                    { role: 'user', content: messageIA }
                ],
                temperature: 0.7,
            };

            try {
                let response = await axios.post('https://api.openai.com/v1/chat/completions', data, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.apiKey}`,
                    },
                });

                console.log(response);

                let respuesta = response.data.choices[0].message.content;

                let formattedRespuesta = this.markdownToHTML(respuesta);

                let messageIA = `
                <div class="iaMessage">
                    <div>${formattedRespuesta}</div>
                </div>
                `
                document.getElementById("messagesIADialog").innerHTML += messageIA;

                this.asking = false;
            } catch (error) {
                console.error(error.response ? error.response.data : error.message);
            }
        },
        savePays(item){
            item.UsrCreateID = this.$fun.getUserID()
            
            item.PsgAmount = this.itemsScheduled.reduce((sum, item) => {
                return sum + parseFloat(item.AsgAmount);
            }, 0);

            if(item.PsgAccount.length < 8){
                this.$fun.alert("El campo Cuenta debe tener minimo 8 caracteres","warning");
                return;
            }

            if(this.itemsScheduled.length == 0){
                this.$fun.alert("Seleccione Pagos","warning");
                return;
            }

            this.itemsScheduled.forEach(item => {item.UsrCreateID = this.$fun.getUserID();});

            item.itemsDetail = this.itemsScheduled;

            item.save();
        },
        rowSelectedRemove(item) {
            this.PsgIdRemove = item.PsgId;
            
            PaymentScheduling.GetItemsPays(this.PsgIdRemove, this.$fun.getUserID())
            .then((r) => {               
                if (r.status == 200) { 
                    item.itemsDetail = r.data;
                    // console.log("item",item);
                    this.savePaysRemove(this.dataRemove);                  
                }
            });
        },
        savePaysRemove(item){
            item.SecStatus = 0;
            item.itemsDetail.forEach((detail) => {detail.SecStatus = 0;});

            PaymentScheduling
                .save(item, this.$fun.getUserID())
                .then(resp => {
                    if (resp.status == 200) {
                        this.$fun.alert("Eliminado correctamente", "success");
                        PaymentScheduling.listPays(this.$fun.getUserID())
                        .then((r) => {             
                            if (r.status == 200) { 
                                this.itemsSearch = r.data;         
                            }
                        });
                        return;
                    }
                })
        },
        addNewPays() {
            this.itemsScheduled = [];
            PaymentScheduling.listPays(this.$fun.getUserID())
            .then((r) => {           
                if (r.status == 200) { 
                    this.itemsSearch = r.data;         
                }
            });
        },
        removedPays() {
            this.rowSelectedRemove(this.dataRemove);
        },
        rowSelected(item) {
            if(item.length > 0 ){
                this.dataRemove = { ...item[0]};
                this.PsgId = item[0].PsgId;
                PaymentScheduling.GetItemsPays(this.PsgId, this.$fun.getUserID())
                .then((r) => {               
                    if (r.status == 200) {
                        this.itemsScheduled = r.data;                       
                    }
                });
            }
        },
        AsgAddCard(row) {
            this.itemsSearch.splice(this.itemsSearch.indexOf(row), 1);
            this.itemsScheduled.push(row);
        },
        AsgDeleteCard(row) {
            if(row.PsgDId == null){
                this.itemsScheduled.splice(this.itemsScheduled.indexOf(row), 1);
                this.itemsSearch.push(row);
            }else{
                const index = this.itemsScheduled.indexOf(row);
                if (index !== -1) {
                    this.itemsScheduled[index].SecStatus = 0;
                }
            }
        }
    },
    computed: {
    filtereditemsScheduled() {
        return this.itemsScheduled.filter(item => item.SecStatus !== 0);
        },
    },
    created() {
        PaymentScheduling.listPays(this.$fun.getUserID())
        .then((r) => {             
            if (r.status == 200) { 
                this.itemsSearch = r.data;         
            }
        });
    }
}

</script>

<style>

.iaMessage div, .userMessage p{
    background: #fffffff2;
    width: 80%;
    border-radius: 10px;
    padding: 5px;
    margin: 0px;
}

.userMessage{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.iaMessage{
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

#containerIADialog{
    position: fixed; 
    bottom: 35px; 
    right: 10px;
    width: 300px; 
    height: 450px; 
    overflow: hidden;
    border-radius: 10px;
    z-index: 10;
    box-shadow: 3px 5px 5px 0px rgba(0, 0, 0, 0.3);
}

#iconIADialog {
    position: absolute;
    left: -50%;
    top: -50%;
    width: 800px;
    height: 800px;
    margin: auto;
    padding: 3px;
    border-radius: 50%;
    background: linear-gradient(0deg, #f7ba2b, #ea5358);
    animation: rotateGradient 2s linear infinite;
}

#containerIA{
    position: fixed; 
    bottom: 35px; 
    right: 10px;
    width: 60px; 
    height: 60px; 
    border-radius: 50%;
    overflow: hidden;
}
#iconIA {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    top: 0;
    left: 0;
    display: flex; 
    justify-content: center; 
    align-items: center;
    padding: 3px;
    background: linear-gradient(0deg, #f7ba2b, #ea5358);
    animation: rotateGradient 2s linear infinite;
}

@keyframes rotateGradient {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

#contentIADialog {
    width: 290px;
    height: 440px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 10px; 
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 10;
    padding: 2px;
}

#manguitoIA {
    width: 54px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff80;
    backdrop-filter: blur(5px);
    border-radius: 50%; 
    cursor: pointer;
    position: absolute;
    top: 3px;
    left: 3px;
    z-index: 10;
}
</style>

<!-- ================= Reconocimiento Facial ================= -->

<!-- <template>
    <div>
        <h2>Detección Facial en Tiempo Real</h2>
        <video ref="video" autoplay></video>
        <canvas ref="canvas"></canvas>

        <h2>Persona Detectada</h2>
        <p v-if="recognizedPerson">Detectado: {{ recognizedPerson }}</p>
        <p v-if="faceExpression">Expresión: {{ faceExpression }}</p>

        <v-btn @click="startCamera">Encender Cámara</v-btn>
        <v-btn @click="stopCamera">Apagar Cámara</v-btn>
        <v-btn @click="focusOnMainPerson">Enfocar en la persona principal</v-btn>
        <v-btn @click="showAllPeople">Mostrar todas las personas</v-btn>
    </div>
</template>
  
<script>
import * as faceapi from 'face-api.js';
import _sHelper from "@/services/HelperService";

export default {
    data() {
        return {
            modelsLoaded: false,
            recognizedPerson: "",
            faceExpression: "",
            ArrayDNI: [
                { name: 'SANTOS VILCHEZ EDINSON PASCUAL', dni: '72103034' },
                { name: 'PAZO TORRES JORGE SAMIR', dni: '73680066' },
                { name: 'CHUMACERO JIMENEZ ELDER JOSE', dni: '75989315' },
                { name: 'ANCAJIMA VALENCIA LUIS DAVID', dni: '71460046' },
                { name: 'CHERO MACALUPU ALEXANDER', dni: '71110972' },
                { name: 'REYES PINEDO JORGE ALBERTO', dni: '44555767' },
                { name: 'FLORES CALLE KENLY ENOC', dni: '46954528' },
                { name: 'RIVAS GARCIA DENILSON YAIR', dni: '71542962' },
                { name: 'CASARIEGO IPANAQUE YANGXI CLARITA', dni: '44636669' },
                { name: 'VALQUI CASTAÑEDA LIDIA RAQUEL', dni: '41532310' },
            ],
            labeledDescriptors: [],
            isCameraActive: true,
            focusOnMain: false,
            lastFacePosition: null,
            noMovementCount: 0
        };
    },
    async mounted() {
        await this.loadModels();
        await this.getImage(); // Obtenemos las imágenes base64 de la base de datos
        this.startVideo();
    },
    methods: {
        async loadModels() {
            const MODEL_URL = '/models'; // Ruta a los modelos
            await faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL);
            await faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL);
            await faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL);
            await faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL);
            this.modelsLoaded = true;
        },

        // Función para obtener las imágenes en base64 y crear descriptores faciales
        async getImage() {
            for (const dniInfo of this.ArrayDNI) {
                const rsp = await _sHelper.getPhotoWorkerBase64(dniInfo.dni, this.$fun.getUserID());
                if (rsp.status === 200) {
                    const base64Image = 'data:image/jpg;base64,' + rsp.data;
                    const img = await faceapi.fetchImage(base64Image);
                    const detections = await faceapi.detectSingleFace(img)
                        .withFaceLandmarks()
                        .withFaceDescriptor();

                    if (!detections) {
                        console.error(`No se detectó rostro en ${dniInfo.name}`);
                        continue;
                    }

                    this.labeledDescriptors.push(new faceapi.LabeledFaceDescriptors(dniInfo.name, [detections.descriptor]));
                } else {
                    console.error(`No se pudo cargar la imagen de ${dniInfo.name}`);
                }
            }
        },

        async startVideo() {
            const video = this.$refs.video;
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: {} });
                video.srcObject = stream;
                video.addEventListener('play', this.recognizeFaces);
            } catch (error) {
                console.error("Error accediendo a la cámara:", error);
            }
        },

        startCamera() {
            const video = this.$refs.video;
            if (!this.isCameraActive) {
                navigator.mediaDevices.getUserMedia({ video: {} })
                    .then(stream => {
                        video.srcObject = stream;
                        video.addEventListener('play', this.recognizeFaces);
                        this.isCameraActive = true; // Marca la cámara como activa
                    })
                    .catch(error => {
                        console.error("Error accediendo a la cámara:", error);
                    });
            }
        },

        stopCamera() {
            const video = this.$refs.video;
            const stream = video.srcObject;
            const tracks = stream.getTracks();
            tracks.forEach(track => track.stop()); // Detenemos todas las pistas de video
            this.isCameraActive = false;
        },

        focusOnMainPerson() {
            this.focusOnMain = true; // Activamos el enfoque en la persona principal
        },

        showAllPeople() {
            this.focusOnMain = false; // Mostramos a todas las personas
        },

        async recognizeFaces() {
            const video = this.$refs.video;
            const canvas = this.$refs.canvas;

            console.log(video.videoWidth, video.videoHeight);

            if (!video || !canvas || this.labeledDescriptors.length === 0 || !this.isCameraActive) return;

            const faceMatcher = new faceapi.FaceMatcher(this.labeledDescriptors, 0.6);
            const displaySize = { width: video.videoWidth, height: video.videoHeight };
            faceapi.matchDimensions(canvas, displaySize);

            setInterval(async () => {
                const detections = await faceapi.detectAllFaces(video, new faceapi.SsdMobilenetv1Options())
                    .withFaceLandmarks()
                    .withFaceDescriptors()
                    .withFaceExpressions(); // Añadimos las expresiones faciales

                const ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                if (this.focusOnMain && detections.length > 0) {
                    // Solo mostrar la primera persona detectada
                    const detection = detections[0];
                    const bestMatch = faceMatcher.findBestMatch(detection.descriptor);
                    this.recognizedPerson = bestMatch.toString();
                    const expressions = detection.expressions;
                    const highestExpression = Object.keys(expressions).reduce((a, b) => expressions[a] > expressions[b] ? a : b);
                    this.faceExpression = highestExpression;

                    faceapi.draw.drawDetections(canvas, [detection]);
                    faceapi.draw.drawFaceLandmarks(canvas, [detection]);
                    this.drawText(ctx, detection, bestMatch, highestExpression);
                } else {
                    // Mostrar todas las personas detectadas
                    detections.forEach(detection => {
                        const bestMatch = faceMatcher.findBestMatch(detection.descriptor);
                        this.recognizedPerson = bestMatch.toString();
                        const expressions = detection.expressions;
                        const highestExpression = Object.keys(expressions).reduce((a, b) => expressions[a] > expressions[b] ? a : b);
                        this.faceExpression = highestExpression;

                        faceapi.draw.drawDetections(canvas, [detection]);
                        faceapi.draw.drawFaceLandmarks(canvas, [detection]);
                        this.drawText(ctx, detection, bestMatch, highestExpression);
                    });
                }
            }, 100);
        },

        drawText(ctx, detection, bestMatch, highestExpression) {
            const { x, y } = detection.detection.box;
            ctx.fillStyle = "white";
            const text = `${bestMatch.toString()} - ${highestExpression}`;
            const textWidth = ctx.measureText(text).width;
            const padding = 10;

            const textY = y - 20;
            ctx.fillRect(x - padding, textY - 30, textWidth + padding * 2, 30);
            ctx.strokeStyle = "black";
            ctx.lineWidth = 2;
            ctx.strokeRect(x - padding, textY - 30, textWidth + padding * 2, 30);
            ctx.fillStyle = "red";
            ctx.fillText(text, x, textY - 10);
        }
    }
};
</script>

<style scoped>
video {
    width: 640px;
    max-width: 480px;
    display: block;
    margin: 10px auto;
}

canvas {
    position: absolute;
    top: 0;
    left: 0;
}

p {
    font-size: 18px;
    text-align: center;
    font-weight: bold;
}

button {
    margin: 10px;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
}
</style> -->

<!-- ================= Servicio de Imagenes ================= -->

<!-- <template>
    <v-card>
        <s-toolbar label="Subir Archivos" dark color="#8e8f91"></s-toolbar>
        <v-container>
            <v-row>
                <v-col>
                    <v-file-input
                        label="Anexo"
                        v-model="file"
                        hide-details
                    />
                </v-col>
                <v-col>
                    <s-text v-model="nameFile" label="Nombre del Archivo"></s-text>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn color="primary" block @click="upload()">Subir</v-btn>
                </v-col>
            </v-row>
        </v-container>
        <AlertSunshine :dialog="dialog" />
    </v-card>
</template>

<script>
import GenUploadFiles from '../../../services/General/GenUploadFiles';

export default {
    data(){
        return {
            file : null,
            nameFile : "",
            genParameter : "routePrueba",
            dialog: false
        }
    },
    methods:{
        upload() {
            this.dialog = true;
            const formData = new FormData();
            formData.append('file', this.file);
            formData.append('nameFile', this.nameFile);
            formData.append('genParameter', this.genParameter);

            GenUploadFiles.upload(formData, this.$fun.getUserID())
            .then((r) => {
                this.$fun.alert('Archivo Guardado Correctamente', 'success');
                this.dialog = false;
            })
            .catch((error) => {
                console.error("Error al subir el archivo", error);
                this.$fun.alert('Error al subir el archivo', 'warning');
                this.dialog = false;
            });
        }
    }
}
</script> -->